import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["plusButton", "minusButton", "dropdown", "currentValue"]
  static values = {
    increment: Number,
    maximum: Number,
  }

  connect() {
    this.currentValue = this.dropdownTarget.value
    this.updateValue(this.currentValue)
  }

  increase() {
    const newValue = parseInt(this.currentValue) + this.incrementValue
    if (newValue <= this.maximumValue) {
      this.currentValue = newValue
      this.updateValue(this.currentValue)
      this.updateDropdown(newValue)
    }
  }

  decrease() {
    const newValue = parseInt(this.currentValue) - this.incrementValue
    if (newValue >= 0) {
      this.currentValue = newValue
      this.updateValue(this.currentValue)
      this.updateDropdown(newValue)
    }
  }

  updateValue(value) {
    this.currentValueTarget.innerHTML = value
  }

  updateDropdown(value) {
    this.dropdownTarget.value = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value)
  }
}
