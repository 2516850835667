import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["text"]
  static values = { percentage: Number }

  connect() {
    this.element.style.setProperty("--progress", 0)
    window.setTimeout(() => {
      this.element.style.setProperty("--progress", this.percentageValue)
      this.textTarget.innerHTML = `${this.percentageValue} %`
    }, 1000)
  }
}
