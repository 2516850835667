import { Controller } from "@hotwired/stimulus"
import Swiper from "swiper"
import "swiper/css"

export default class extends Controller {
  static targets = ["swiper", "devtool"]
  static values = {
    initialSlide: { type: Number, default: 0 },
  }

  connect() {
    this.swiper = new Swiper(this.swiperTarget, {
      direction: "vertical",
      on: {
        slideChange: this.handleSlideChange.bind(this),
        init: this.setProgress.bind(this),
      },
      initialSlide: this.initialSlideValue,
    })

    this.hasDevtoolTarget && this.createDevtool()

    if (this.element.dataset.persistSlide !== "false") {
      const savedSlide = localStorage.getItem(
        `${this.element.dataset.slug}-swiper-index`,
      )
      const slideIndex = savedSlide ? savedSlide.replace("#", "") : null
      if (slideIndex) {
        this.swiper.slideTo(slideIndex)
        this.hasDevtoolTarget &&
          this.devtoolTarget
            .querySelectorAll(`span[data-id='${slideIndex}']`)[0]
            .classList.add("!bg-pink-600", "!text-white")
      }
    }

    document.documentElement.classList.add("overscroll-none")
    document.addEventListener(
      "page-slider:removeActive",
      this.handleRemoveActiveEvent.bind(this),
    )
  }

  disconnect() {
    document.documentElement.classList.remove("overscroll-none")
    document.removeEventListener(
      "page-slider:removeActive",
      this.handleRemoveActiveEvent.bind(this),
    )
  }

  resetSwiperIndex() {
    localStorage.removeItem(`${this.element.dataset.slug}-swiper-index`)
  }

  createDevtool() {
    const itemCount = this.swiper.slides.length

    for (let i = 0; i < itemCount; i++) {
      const element = document.createElement("span")
      element.textContent = i
      element.dataset.id = i
      element.addEventListener("click", () => {
        this.swiper.slideTo(i)
      })
      this.devtoolTarget.appendChild(element)
    }
  }

  handleSlideChange(swiper) {
    const previousSlide = swiper.slides[swiper.activeIndex - 1]
    if (this.element.dataset.persistSlide !== "false") {
      if (swiper.activeIndex >= 0) {
        localStorage.setItem(
          `${this.element.dataset.slug}-swiper-index`,
          swiper.activeIndex,
        )
      }
    }

    if (previousSlide) {
      const hadNotificationsScreen =
        previousSlide.querySelectorAll(
          "[data-controller=bridge--notifications]",
        ).length === 1
      if (hadNotificationsScreen) {
        const form = document.forms["edit-notifications"]
        form.requestSubmit()
      }
    }
    this.setProgress()
    if (this.hasDevtoolTarget && this.devtoolTarget.children.length > 0) {
      this.devtoolTarget.querySelectorAll("span").forEach((element) => {
        element.classList.remove("!bg-pink-600", "!text-white")
      })
      this.devtoolTarget
        .querySelectorAll(`span[data-id='${swiper.activeIndex}']`)[0]
        .classList.add("!bg-pink-600", "!text-white")
    }
  }

  setProgress() {
    if (undefined === this.swiper) {
      // Workaround for a bug when returning from user medication. Somehow the swiper is initialized twice.
      setTimeout(this.setProgress.bind(this), 200)
      return false
    }
    const progress =
      ((this.swiper.activeIndex + 1) * 100) / this.swiper.slides.length
    this.dispatch("progress", { detail: { progress } })
  }

  handleRemoveActiveEvent() {
    this.next()
  }

  next() {
    this.swiper.slideNext()
  }
}
