import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "dropdown",
    "visualisation",
    "increaseButton",
    "currentAmountInLiter",
  ]

  static values = {
    minimum: Number,
    maximum: Number,
    current: Number,
    imageUrl: String,
    literPerBottle: Number,
  }

  connect() {
    this.buildVisualisation(this.currentValue)
  }

  decrease() {
    const idOfSelectedBottle = event.currentTarget.dataset.id
    const liter =
      idOfSelectedBottle * this.literPerBottleValue - this.literPerBottleValue
    this.currentValue = liter
    this.buildVisualisation(liter)
    this.updateDropdown(liter)
  }

  increase() {
    const liter = this.currentValue + this.literPerBottleValue
    this.currentValue = liter
    this.buildVisualisation(liter)
    this.updateDropdown(liter)
  }

  updateDropdown(liter) {
    this.dropdownTarget.value = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    }).format(liter)
  }

  buildVisualisation(liter) {
    const bottlesToBuild = liter / this.literPerBottleValue

    this.visualisationTarget.innerHTML = ""

    for (let i = 0; i < bottlesToBuild; i++) {
      const image = document.createElement("img")
      image.classList.add("block", "cursor-pointer")
      image.src = this.imageUrlValue
      image.dataset.action = "touchstart->drink#decrease"
      image.dataset.id = i + 1
      this.visualisationTarget.appendChild(image)
    }

    liter < this.maximumValue
      ? this.toggleDrinkButton(false)
      : this.toggleDrinkButton(true)

    this.currentAmountInLiterTarget.innerHTML = new Intl.NumberFormat(
      "de-DE",
    ).format(liter)
  }

  toggleDrinkButton(status) {
    this.increaseButtonTarget.classList.toggle("hidden", status)
  }
}
