import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["analysis", "home", "discover", "settings", "tab", "frame"]

  static values = {
    tab: { type: String, default: "home" },
    previousTab: { type: String, default: "" },
    localStorageKey: { type: String, default: "tabs:active" },
  }

  static classes = ["active"]

  connect() {
    if (this.tabFromLocalStorage) {
      this.tabValue = this.tabFromLocalStorage
    }
  }

  select({ params: { tab } }) {
    this.tabValue = tab
  }

  tabValueChanged(newTab, oldTab) {
    this.tabTargets.forEach((tab) => {
      tab.classList.toggle(
        this.activeClass,
        tab.dataset.tabsTabParam === newTab,
      )
    })
    if (this.hasAnalysisTarget) {
      this.analysisTarget.classList.toggle("hidden", newTab !== "analysis")
    }
    if (this.hasHomeTarget) {
      this.homeTarget.classList.toggle("hidden", newTab !== "home")
    }
    if (this.hasDiscoverTarget) {
      this.discoverTarget.classList.toggle("hidden", newTab !== "discover")
    }
    if (this.hasSettingsTarget) {
      this.settingsTarget.classList.toggle("hidden", newTab !== "settings")
    }
    // reload the analysis tab if it is selected
    if (newTab === "analysis") {
      this.reloadActiveFrame()
    }

    if (newTab === "settings") {
      this.previousTabValue = oldTab
    }

    if (oldTab) {
      localStorage.setItem(this.localStorageKeyValue, newTab)
    }
  }

  goToPreviousTab() {
    if (this.previousTabValue.length > 0) {
      this.tabValue = this.previousTabValue
      this.previousTabValue = ""
    }
  }

  reloadAllWhenSubmitWasSuccessful({ detail: { success } }) {
    if (success) {
      this.frameTargets.forEach((frame) => frame.reload())
    }
  }

  reloadActiveWhenSubmitWasSuccessful({ detail: { success } }) {
    if (success) {
      this.reloadActiveFrame()
    }
  }

  reloadActiveFrame() {
    this.activeFrame.reload()
  }

  resetStoredTab() {
    localStorage.removeItem(this.localStorageKeyValue)
  }

  get tabFromLocalStorage() {
    return localStorage.getItem(this.localStorageKeyValue)
  }

  get activeTab() {
    return this.tabTargets.find(
      (target) => target.dataset.tabsTabParam === this.tabValue,
    )
  }

  get activeFrame() {
    return this[`${this.activeTab.dataset.tabsTabParam}Target`].querySelector(
      "turbo-frame",
    )
  }
}
