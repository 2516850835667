import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal"]

  show() {
    this.modalTarget.showModal()
  }

  close(event) {
    event.stopPropagation()
    event.preventDefault()
    this.modalTarget.close()
  }
}
