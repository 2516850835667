import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  hide() {
    this.element.classList.add("hidden")
    localStorage.setItem("headacy-plus-promotion-banner-seen", true)
  }

  connect() {
    if (
      localStorage.getItem("headacy-plus-promotion-banner-seen") &&
      this.element.dataset.withCloseButton === "true"
    ) {
      this.element.classList.add("hidden")
    }
  }
}
