import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["meal", "addMeal", "cappucinoImage", "mealsList"]
  static values = {
    prefix: { type: String, default: "tracking_item" },
    minMeals: { type: Number, default: 1 },
    maxMeals: { type: Number, default: 5 },
  }

  connect() {
    this.updateVisibility()
  }

  add({ currentTarget, params: { type, time, name } }) {
    if (this.mealTargets.length >= this.maxMealsValue) {
      return
    }
    const index =
      this.configureMealsCount === 0 ? 0 : this.configureMealsCount - 1
    const mealTemplate = this.mealTargets[index]
    let newMeal
    let removeHiddenClass = false
    if (
      this.configureMealsCount === 0 &&
      mealTemplate.classList.contains("hidden")
    ) {
      newMeal = mealTemplate
      removeHiddenClass = true
    } else {
      newMeal = mealTemplate.cloneNode(true)
    }
    const typeInput = newMeal.querySelector("input[type='hidden']")
    this.setNewInputsValues(typeInput, type)
    const timeInput = newMeal.querySelector("input[type='time']")
    this.setNewInputsValues(timeInput, time)
    newMeal.querySelector("[data-food-target='name']").innerText = name
    newMeal.querySelector("svg").innerHTML = currentTarget.querySelector(
      "template[data-food-target='icon']",
    ).innerHTML
    if (removeHiddenClass) newMeal.classList.remove("hidden")
    mealTemplate.after(newMeal)
    this.updateVisibility()
  }

  remove({ currentTarget }) {
    const mealRow = currentTarget.closest("[data-behaviour=meal-row]")
    if (this.configureMealsCount <= this.minMealsValue) return false

    if (this.configureMealsCount > 1) {
      mealRow.parentElement.removeChild(mealRow)
    } else {
      // Just disable inputs
      Array.from(mealRow.querySelectorAll("input")).forEach((input) => {
        input.disabled = true
      })
      // and hide the row
      mealRow.classList.add("hidden")
    }
    this.updateVisibility()
  }

  updateVisibility() {
    this.addMealTarget.classList.toggle(
      "hidden",
      this.mealTargets.length >= this.maxMealsValue,
    )
    if (this.hasCappucinoImageTarget) {
      this.cappucinoImageTarget.classList.toggle(
        "hidden",
        this.configureMealsCount > 0,
      )
    }
    if (this.hasMealsListTarget) {
      this.mealsListTarget.classList.toggle(
        "hidden",
        this.configureMealsCount === 0,
      )
    }
  }

  get configuredMeals() {
    return this.mealTargets.filter((div) => !div.classList.contains("hidden"))
  }

  get configureMealsCount() {
    return this.configuredMeals.length
  }

  setNewInputsValues(element, value) {
    element.value = value
    element.name = element.name.replace(
      /\[\d+\]/,
      `[${this.configureMealsCount}]`,
    )
    element.id = element.id.replace(/_\d+_/, `_${this.configureMealsCount}_`)
    element.disabled = false
  }
}
