import { Controller } from "@hotwired/stimulus"
import { patch } from "@rails/request.js"

export default class extends Controller {
  connect() {
    document.body.addEventListener(
      "engagement-heartbeat:send",
      this.handleHearbeatEvent.bind(this),
    )
    document.body.addEventListener("touchstart", this.handleTouch.bind(this))
  }

  disconnect() {
    document.body.removeEventListener(
      "engagement-heartbeat:send",
      this.handleHearbeatEvent.bind(this),
    )
    document.body.removeEventListener("touchstart", this.handleTouch.bind(this))
  }

  handleTouch() {
    this.debounce(() => this.handleHearbeatEvent(), 5000)()
  }

  handleHearbeatEvent() {
    patch("/user/session/prolong")
  }

  debounce(func, timeout = 300) {
    return (...args) => {
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        func.apply(this, args)
      }, timeout)
    }
  }
}
