import { Controller } from "@hotwired/stimulus"
import Chart from "chart.js/auto"
import resolveConfig from "tailwindcss/resolveConfig"
import tailwindConfig from "../../../tailwind.config.js"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ["canvas", "timeRange", "noResultHint"]

  static values = {
    dataUrl: String,
    timeRange: { type: String, default: "week" },
    metric: String,
  }

  connect() {
    this.chart = new Chart(this.canvasTarget, {
      type: "pie",
      data: this.chartData,
      options: this.chartOptions,
    })
  }

  selectTimeRange({ detail }) {
    this.timeRangeValue = detail.timeRange
  }

  timeRangeValueChanged(newTimeRange) {
    this.render()
  }

  // actions
  async render() {
    const url = new URL(this.dataUrlValue)
    const searchOptions = { time_range: this.timeRangeValue }
    if (this.hasMetricValue) searchOptions.metric = this.metricValue

    url.search = new URLSearchParams(searchOptions).toString()

    const response = await get(url.toString())
    if (response.ok) {
      const data = await response.json
      this.updateChart(data)
    }
  }

  updateChart({ data, labels }) {
    const isEmpty = data.length === 0
    this.canvasTarget.classList.toggle("!invisible", isEmpty)
    this.noResultHintTarget.classList.toggle("hidden", !isEmpty)

    this.chart.data.labels = labels
    this.chart.data.datasets[0].data = data

    this.chart.update()
  }

  // private

  get chartData() {
    const colors = resolveConfig(tailwindConfig).theme.colors

    return {
      labels: [],
      datasets: [
        {
          data: [1, 1, 1],
          backgroundColor: [
            colors.orange["400"],
            colors.lavender["300"],
            colors.lavender["400"],
            colors.yellow,
            colors.orange["600"],
          ],
          hoverOffset: 4,
        },
      ],
    }
  }

  get chartOptions() {
    return {
      plugins: {
        legend: {
          position: "bottom",
        },
        tooltip: {
          displayColors: false,
          callbacks: {
            label: (context) => {
              const dataArr = context.chart.data.datasets[0].data
              const percent = this.percentage(dataArr, context.raw) + "%"
              return ` ${percent} (${context.formattedValue})`
            },
          },
        },
      },
    }
  }

  percentage(array, value) {
    let sum = 0
    array.map((data) => (sum += data))
    return ((value * 100) / sum).toFixed(0)
  }
}
