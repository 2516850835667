import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  resetOnboarding() {
    localStorage.removeItem("onboarding-swiper-index")
  }

  clearLocalStorage() {
    localStorage.clear()
  }

  reloadWindow() {
    window.location.reload()
  }
}
