import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["startedAt", "endedAt", "errorMessage"]

  static values = {
    hasError: { type: Boolean, default: true },
  }

  static classes = ["hidden"]

  connect() {
    const targets = [this.startedAtTarget, this.endedAtTarget]
    targets.forEach((target) => {
      target
        .querySelectorAll("input")
        .forEach((input) =>
          input.addEventListener("change", this.validate.bind(this)),
        )
    })
  }

  validate() {
    this.hasErrorValue = this.endedAtVisible && this.startedAt >= this.endedAt
  }

  get endedAtVisible() {
    return this.endedAtTarget.offsetParent !== null
  }

  hasErrorValueChanged() {
    if (!this.hasErrorMessageTarget) return true

    this.errorMessageTarget.classList.toggle(
      this.hiddenClass,
      !this.hasErrorValue,
    )
  }

  get startedAt() {
    return this.dateTimeFor(this.startedAtTarget)
  }

  get endedAt() {
    return this.dateTimeFor(this.endedAtTarget)
  }

  dateTimeFor(target) {
    const date = this.targetValue(target, "date")
    const time = this.targetValue(target, "time")
    return new Date(`${date} ${time}`)
  }

  targetValue(target, type) {
    return target.querySelector(`input[type=${type}]`).value
  }
}
