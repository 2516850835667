import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["textarea", "input", "form"]

  static values = { inputRequired: Boolean }

  connect() {
    this.updateButtonDisabledState()
  }

  inputChanged() {
    this.updateTextAreaValue()
    this.formTarget.requestSubmit()
    this.updateButtonDisabledState()
  }

  updateTextAreaValue() {
    const data = this.checkedInput.map((input) => input.value)
    this.textareaTarget.innerText = JSON.stringify(data)
  }

  updateButtonDisabledState() {
    if (this.inputRequiredValue) {
      this.nextButton.disabled = this.checkedInput.length < 1
    }
  }

  get checkedInput() {
    return this.inputTargets.filter((input) => input.checked)
  }

  get nextButton() {
    return document.querySelector("[data-behaviour=next-button]")
  }
}
