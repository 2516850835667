import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static values = { url: String }

  open(event) {
    let url = this.urlValue
    if (event.params.noRefresh) {
      url = `${url}?no-refresh=1`
    }
    Turbo.visit(url)
  }
}
