import { Controller } from "@hotwired/stimulus"
import { patch } from "@rails/request.js"

export default class extends Controller {
  static targets = ["overlay", "turboFrame", "playerReaderButton"]
  static values = {
    currentSlug: {
      type: String,
      default: "",
    },
    completedSlugs: {
      type: Array,
      default: [],
    },
  }

  load({ params: { slug } }) {
    this.currentSlugValue = slug
    this.playerReaderButtonTarget.dataset.discoverPlayerWithinProgramParam = true
  }

  currentSlugValueChanged(current, previous) {
    this.updateTurboFrameTargetSrc()
    this.updateOverlayVisiblity()
  }

  overlayShouldBeLoaded() {
    return this.currentSlugValue.length > 0
  }

  async playerThreshold({ detail }) {
    if (this.overlayShouldBeLoaded()) {
      this.markComplete(detail.slug)
    }
  }

  readerOpened(event) {
    const slug = event.params.slug
    this.markComplete(slug)
  }

  async markComplete(slug) {
    if (this.currentSlugValue.length === 0) return false
    if (this.completedSlugsValue.includes(slug)) return true

    const result = await patch(
      `/programs/${this.currentSlugValue}/complete/${slug}`,
      {
        contentType: "application/json",
      },
    )
    if (result.ok) {
      this.completedSlugsValue.push(slug)
      this.reloadFrame()
    }
  }

  reloadFrame() {
    this.turboFrameTarget.reload()
  }

  close() {
    this.currentSlugValue = ""
    this.turboFrameTarget.innerHTML = ""
    delete this.playerReaderButtonTarget.dataset
      .discoverPlayerWithinProgramParam
  }

  updateOverlayVisiblity() {
    this.overlayTarget.classList.toggle("hidden", !this.overlayShouldBeLoaded())
  }

  updateTurboFrameTargetSrc() {
    if (this.overlayShouldBeLoaded()) {
      this.turboFrameTarget.src = `/programs/${this.currentSlugValue}`
    }
  }
}
